import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LibriAggiorna from "./LibriAggiorna";
import PrenotaLibro from "./PrenotaLibro";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Pallino, convertiData, controllaLogin } from "../inc/inc";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

const Comp = () => {
  const ruoloLogin = controllaLogin();

  let location = useLocation();

  return (
    <>
      <h1>INFORMATIVA</h1>
      <h2>ai sensi dell’articolo 13 del Regolamento UE 679/2016</h2>

      <p>
        <strong>Elea S.p.a. (</strong>di seguito, il “<strong>Titolare</strong>
        ”), in qualità di Titolare del trattamento, ai sensi dell’articolo 13
        del Regolamento UE 679/2016 (di seguito, il “
        <strong>Regolamento privacy</strong>”), e successive modificazioni ed
        integrazioni, raccoglie e successivamente tratta dati personali degli
        utenti richiedenti le credenziali di accesso al portale “La nostra
        Biblioteca” di Elea (di seguito, l’“<strong>Interessato</strong>”).
      </p>
      <br />
      <h2> 1. Finalità e modalità del trattamento.</h2>

      <p>
        I dati personali dell’Interessato sono trattati nell’ambito della
        normale attività del Titolare, per il perseguimento delle seguenti
        finalità:
      </p>

      <ol>
        <li>
          Accesso al sistema informatico “La nostra Biblioteca” ed ai servizi
          dallo stesso messi a disposizione tramite l’assegnazione di
          credenziali individuali;
        </li>
        <li>
          adempimenti di carattere amministrativo strettamente connessi al
          servizio;
        </li>
        <li>
          adempimento di specifici obblighi previsti dalla legge, da un
          regolamento o dalla normativa comunitaria.
        </li>
      </ol>

      <p>
        Il trattamento dei dati personali avviene, sotto l’autorità del
        Titolare, da parte di soggetti specificamente incaricati, autorizzati ed
        istruiti al trattamento ai sensi dell’art. 29 del Regolamento privacy,
        mediante strumenti informatici o telematici, con logiche strettamente
        correlate alle finalità e comunque in modo da garantire la riservatezza
        e la sicurezza dei dati personali. Il trattamento dei dati personali può
        altresì avvenire, per conto del Titolare, ad opera di Responsabili del
        trattamento appositamente designati ai sensi dell’art. 28 del
        Regolamento privacy.
      </p>
      <p>
        I dati personali saranno conservati per un periodo determinato in base a
        criteri fondati sulla natura e sulla durata del Contratto e sulle
        esigenze di tutela degli interessi dell’Interessato.
      </p>
      <br />
      <h2>
        2. Base giuridica del trattamento, natura del conferimento e conseguenze
        di un eventuale rifiuto, consenso dell’Interessato.
      </h2>
      <p>
        <strong>
          <em>
            2.1) Finalità di cui al precedente paragrafo 1, punti 1., 2. e 3.
          </em>
        </strong>
      </p>
      <p>
        Con riferimento alle finalità di cui al precedente paragrafo 1, punti
        1., 2. e 3., il conferimento dei dati personali è obbligatorio e
        costituisce requisito necessario per l’erogazione del servizio; infatti,
        il mancato conferimento determina l’impossibilità di ricevere le
        credenziali di accesso al portale Fincloud.
      </p>
      <br />
      <h2>
        3. Soggetti o categorie di soggetti ai quali i dati personali possono
        essere comunicati e ambito di comunicazione.
      </h2>
      <p>
        In relazione alle finalità del trattamento sopra indicate, e nei limiti
        strettamente pertinenti alle medesime, i dati personali dell’Interessato
        saranno o potranno essere comunicati alle seguenti categorie di
        soggetti:
      </p>
      <ul>
        <li>
          all’Amministrazione finanziaria e ad altre pubbliche Autorità, ove
          imposto dalla legge o su loro richiesta;
        </li>
        <li>
          alle società di cui il Titolare si avvale, preposte allo svolgimento
          di attività connesse, strumentali o conseguenti all’erogazione del
          servizio nominate responsabili esterne del trattamento;
        </li>
      </ul>
      <p>
        I dati dell’Interessato non saranno oggetto di diffusione né saranno
        trasferiti in paesi non appartenenti all’Unione Europea.
      </p>
      <br />
      <h2> 4.Diritti dell’interessato.</h2>
      <p>
        Gli artt. 15 e ss. del Regolamento privacy conferiscono all’Interessato
        il diritto di ottenere:
      </p>
      <ul>
        <li>
          la conferma dell’esistenza o meno di dati personali che lo riguardano,
          anche se non ancora registrati, e la loro comunicazione in forma
          intelligibile;
        </li>
        <li>
          l’indicazione dell’origine dei dati personali, delle finalità e
          modalità del trattamento, della logica applicata in caso di
          trattamento effettuato con l’ausilio di strumenti elettronici, degli
          estremi identificativi del titolare;
        </li>
        <li>
          l’aggiornamento, rettifica, integrazione, cancellazione,
          trasformazione in forma anonima o il blocco dei dati trattati in
          violazione di legge (compresi quelli di cui non è necessaria la
          conservazione in relazione agli scopi per i quali i dati sono raccolti
          o successivamente trattati). L’attestazione che tali operazioni sono
          state portate a conoscenza di coloro ai quali i dati sono stati
          comunicati o diffusi (anche per quanto riguarda il loro contenuto),
          eccettuato il caso in cui tale adempimento si riveli impossibile o
          comporti un impiego di mezzi manifestamente sproporzionato rispetto al
          diritto tutelato.
        </li>
      </ul>
      <br />
      <h2>L’Interessato ha inoltre il diritto:</h2>
      <ul>
        <li>
          di revocare in qualsiasi momento il consenso prestato al trattamento
          dei dati personali, ove previsto (senza pregiudizio della liceità del
          trattamento basata sul consenso prestato prima della revoca);
        </li>
        <li>
          di opporsi, in tutto o in parte, per motivi legittimi, al trattamento
          dei dati personali che lo riguardano, ancorché pertinenti allo scopo
          della raccolta;
        </li>
        <li>
          di opporsi, in tutto o in parte al trattamento di dati personali che
          lo riguardano a fini di invio di materiale pubblicitario o di vendita
          diretta o per il compimento di ricerche di mercato o di comunicazione
          commerciale;
        </li>
        <li>
          di proporre reclamo al Garante per la protezione dei dati personali
          nei casi previsti dal Regolamento privacy;
        </li>
        <li>
          alla portabilità dei dati personali nei limiti di cui all’art. 20 del
          Regolamento privacy.
        </li>
      </ul>
      <p>
        Per conoscere l’elenco dettagliato e costantemente aggiornato dei
        soggetti cui i dati personali dell’Interessato possono essere comunicati
        e per esercitare i diritti di cui agli artt. 15 e ss. del Regolamento
        privacy, il medesimo può rivolgersi al Titolare del trattamento alla
        mail <b>privacy@eleaspa.it</b>
      </p>
      <br />
      <br />
      <br />
    </>
  );
};

export default Comp;
