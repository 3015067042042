import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import BookIcon from "@mui/icons-material/Book";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import DownloadIcon from "@mui/icons-material/Download";
import Container from "@mui/material/Container";

const Comp = (props) => {
  const ruoloLogin = props.controllaLogin();
  //console.log(444, ruoloLogin);
  const [menuAperto, setMenuAperto] = useState(false);
  const [apriMenuUtente, setApriMenuUtente] = useState(false);
  const [emailUtente, setEmailUtente] = useState("");
  const [nomeCognome, setNomeCognome] = useState("");

  let location = useLocation();

  useEffect(() => {
    //in file inc. redirect se non in pagina libri
    props.controllaLogin();
    conteggioPrenotazioniUtente();
    getUtente();
  }, [location]);

  async function conteggioPrenotazioniUtente() {
    const response = await fetch(
      process.env.REACT_APP_BACKEND + "/conteggioPrenotazioniUtente",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: Cookies.get("utenteToken"),
        },
        body: JSON.stringify({}),
      }
    );
    let res = await response.json();
    //console.log(333, res);
    if (Cookies.get("utenteAdmin") != "true") {
      Cookies.set("conteggioPrenotazioniUtente", res.conteggioPrenUtente);
    } else {
      Cookies.remove("conteggioPrenotazioniUtente");
    }
  }

  async function getUtente() {
    const response = await fetch(process.env.REACT_APP_BACKEND + "/utenteGet", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: Cookies.get("utenteToken"),
      },
      body: JSON.stringify({}),
    });
    let res = await response.json();
    //console.log(444, res);
    setEmailUtente(res.email);
    setNomeCognome(res.nome + " " + res.cognome);
  }

  const logout = () => {
    Cookies.remove("utenteAdmin");
    Cookies.remove("utenteId");
    Cookies.remove("utenteToken");
    window.location.href = "/";
  };

  return (
    <>
      <div className="barraPiccola">
        <Container>
          <p>LA NOSTRA BIBLIOTECA</p>

          {ruoloLogin ? (
            <>
              <div className="wrapUtente">
                <Button color="inherit" onClick={logout}>
                  Logout
                </Button>

                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => setApriMenuUtente(!apriMenuUtente)}
                  color="inherit"
                >
                  {/* <AccountCircle /> */}
                  <img
                    src="/img/user-profilo.png"
                    style={{ height: "25px" }}
                    className="pointer"
                  />
                </IconButton>
              </div>
              <Menu
                open={apriMenuUtente}
                anchorEl={document.querySelector(".wrapUtente")}
                id="menu-appbar"
                onClick={() => setApriMenuUtente(!apriMenuUtente)}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={() => setApriMenuUtente(!apriMenuUtente)}>
                  <div>
                    <b>{nomeCognome}</b>
                    <br />
                    {emailUtente}
                  </div>
                </MenuItem>
              </Menu>
            </>
          ) : (
            <Button color="inherit" href="/login">
              Login
            </Button>
          )}
        </Container>
      </div>
      <AppBar position="static" sx={{ mb: 2 }} className="barraTop">
        <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Toolbar>
            <Link to="/" style={{ marginRight: "15px", display: "flex" }}>
              <img
                src="/img/logo_login.png"
                style={{ width: "80px", padding: "10px 0" }}
              />
            </Link>

            <IconButton
              className="menuMobile"
              size="large"
              edge="start"
              color="primary"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => setMenuAperto(!menuAperto)}
            >
              <MenuIcon />
            </IconButton>

            <div className="menuMain">
              <Link to="/">
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ mr: 2 }}
                  className="valign nascondiMobile"
                >
                  {/* <MenuBookIcon /> */}
                  <img src="/img/icon-libri_65x52.png" />
                  <span>Libri</span>
                </Typography>
              </Link>
              <Link to="/prenotazioni">
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ flexGrow: 1 }}
                  className="valign nascondiMobile"
                >
                  {/* <BookIcon /> */}
                  <img src="/img/icon-prenotazioni_65x52.png" />
                  <span>Prenotazioni</span>
                </Typography>
              </Link>
              {/* se admin */}
              {Cookies.get("utenteAdmin") == "true" ? (
                <Link to="/admin">
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ ml: 2 }}
                    className="valign nascondiMobile"
                  >
                    <span>Report</span>
                  </Typography>
                </Link>
              ) : (
                ""
              )}
            </div>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        className="menuLaterale"
        anchor="left"
        open={menuAperto}
        onClose={() => setMenuAperto(!menuAperto)}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/"
              onClick={() => setMenuAperto(!menuAperto)}
            >
              <ListItemIcon>
                <img src="/img/icon-libri_65x52.png" />
              </ListItemIcon>
              <ListItemText primary="Libri" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/prenotazioni"
              onClick={() => setMenuAperto(!menuAperto)}
            >
              <ListItemIcon>
                <img src="/img/icon-prenotazioni_65x52.png" />
              </ListItemIcon>
              <ListItemText primary="Prenotazioni" />
            </ListItemButton>
          </ListItem>

          {/* <ListItem disablePadding>
            <ListItemButton
              component="a"
              href="/CATALOGO BIBLIOTECA ELEA INTERATTIVO.pdf"
              target="_blank"
            >
              <ListItemIcon>
                <DownloadIcon />
              </ListItemIcon>
              <ListItemText primary="Scarica Catalogo" />
            </ListItemButton>
          </ListItem> */}
        </List>
      </Drawer>
    </>
  );
};

export default Comp;
