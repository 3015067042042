import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Cookies from "js-cookie";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Comp(props) {
  const [titolo, setTitolo] = useState("");
  const [autore, setAutore] = useState("");
  const [casaEd, setCasaEd] = useState("");
  const [genere, setGenere] = useState("");
  const [anno, setAnno] = useState("");
  const [descrizione, setDescrizione] = useState("");
  const [lingua, setLingua] = useState("");
  const [pagine, setPagine] = useState("");
  const [ean, setEan] = useState("");
  const [disponibile, setDisponibile] = useState(false);
  const [immagine, setImmagine] = useState(null)
  const [idInterno, setIdInterno] = useState("");

  async function query(id) {
    console.log("aggiorna query");
    const response = await fetch(
      process.env.REACT_APP_BACKEND + "/libriSingolo",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: Cookies.get("utenteToken"),
        },
        body: JSON.stringify({ id }),
      }
    );
    let res = await response.json();
    console.log(res);
    setAutore(res.autore);
    setCasaEd(res.casaeditrice);
    setTitolo(res.titolo);
    setGenere(res.genere);
    setAnno(res.anno);
    setDescrizione(res.descrizione);
    setLingua(res.lingua);
    setPagine(res.pagine);
    setEan(res.ean);
    setDisponibile(res.disponibile == 1 ? true : false);
    setImmagine(res.immagine)
    setIdInterno(res.idInterno)
  }

  useEffect(() => {
    if (props.idElem) {
      query(props.idElem);
    }
  }, [props.idElem, props.open]);

  const aggiornaElemento = async (id) => {
    var formData = new FormData();
    formData.append("id", id);
    formData.append("titolo", titolo);
    formData.append("autore", autore);
    formData.append("casaEd", casaEd);
    formData.append("genere", genere);
    formData.append("anno", anno);
    formData.append("descrizione", descrizione);
    formData.append("lingua", lingua);
    formData.append("pagine", pagine);
    formData.append("ean", ean);
    formData.append("disponibile", disponibile);
    formData.append("immagine", document.querySelector(".immagine").files[0]);
    formData.append("idInterno", idInterno);

    const response = await fetch(
      process.env.REACT_APP_BACKEND + "/libriAggiorna",
      {
        method: "POST",
        headers: {
          //"Content-Type": "application/json",
          Authorization: Cookies.get("utenteToken"),
        },
        body: formData
        // JSON.stringify({
        //   id,
        //   titolo,
        //   autore,
        //   casaEd,
        //   genere,
        //   anno,
        //   descrizione,
        //   lingua,
        //   pagine,
        //   ean,
        //   disponibile,
        // }),
      }
    );
    let res = await response.json();
    console.log(res);
    props.fnChiudi();
    query(props.idElem);
  };

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.fnChiudi}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", mb: 2 }} className="barraEdit">
      <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.fnChiudi}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <img
              src="/img/logo_main.png"
              style={{ width: "50px"}}
            />
          {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Aggiorna
          </Typography> */}
          {/* <Button
            autoFocus
            color="inherit"
            onClick={() => {
              props.fnChiudi();
              aggiornaElemento(props.idElem);
            }}
          >
            save
          </Button> */}
        </Toolbar>
        </Container>
      </AppBar>
      <Container>
      <h1>AGGIORNA LIBRO</h1>
        <Grid container spacing={2}>
        
          <Grid item xs={12} sm={6}>
            <TextField
              label="Titolo"
              required
              variant="filled"
              value={titolo || ""}
              fullWidth
              onChange={(e) => setTitolo(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Autore"
              required
              variant="filled"
              value={autore || ""}
              fullWidth
              onChange={(e) => setAutore(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Casa Editrice"
              variant="filled"
              value={casaEd || ""}
              fullWidth
              onChange={(e) => setCasaEd(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Genere"
              required
              variant="filled"
              value={genere || ""}
              fullWidth
              onChange={(e) => setGenere(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Anno"
              type="number"
              variant="filled"
              value={anno || ""}
              fullWidth
              onChange={(e) => setAnno(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Lingua"
              variant="filled"
              value={lingua || ""}
              fullWidth
              onChange={(e) => setLingua(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Pagine"
              type="number"
              variant="filled"
              value={pagine || ""}
              fullWidth
              onChange={(e) => setPagine(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="EAN"
              variant="filled"
              value={ean || ""}
              fullWidth
              onChange={(e) => setEan(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Descrizione"
              variant="filled"
              multiline
              maxRows="10"
              value={descrizione || ""}
              fullWidth
              onChange={(e) => setDescrizione(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="ID Interno"
              variant="filled"
              maxRows="10"
              value={idInterno || ""}
              fullWidth
              onChange={(e) => setIdInterno(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={disponibile}
                  onChange={(e) => setDisponibile(e.target.checked)}
                />
              }
              label="Prenotabile"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <img src={immagine && process.env.REACT_APP_BACKEND+"/media/"+immagine} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <input type="file" className="immagine" />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={() => {
                props.fnChiudi();
                aggiornaElemento(props.idElem);
              }}
            >
              Salva
            </Button>
            <br />
            <br />
            <br />
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
}
