import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LibriAggiorna from "./LibriAggiorna";
import PrenotaLibro from "./PrenotaLibro";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Pallino, convertiData, controllaLogin } from "../inc/inc";
import Pagination from "@mui/material/Pagination";
import Cookies from "js-cookie";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

const Comp = () => {
  const ruoloLogin = controllaLogin();

  let location = useLocation();

  const [libri, setLibri] = useState([]);
  const [totaleLibri, setTotaleLibri] = useState(0);

  const [listaAutori, setListaAutori] = useState([]);
  const [listaGeneri, setListaGeneri] = useState([]);

  const [elementoDaAggiornare, setElementoDaAggiornare] = useState(null);
  const [apriAggiorna, setApriAggiorna] = useState(false);
  const [apriPrenota, setApriPrenota] = useState(false);

  const [cercaTitolo, setCercaTitolo] = useState("");
  const [cercaAutore, setCercaAutore] = useState("");
  const [cercaGeneri, setCercaGeneri] = useState("");
  const [pagina, setPagina] = useState(1);
  const [risPerPagina, setRisPerPagina] = useState(20);

  const controllaDisponibilita = (ogg) => {
    ogg.forEach((el) => {
      const dataFine = new Date(el.dataFine);
      const dataOdierna = new Date();
      const diffDate = dataOdierna - dataFine;
      if (diffDate < 0) {
        el.disponibilePren = 0;
      } else {
        el.disponibilePren = 1;
      }
    });

    return ogg;
  };

  async function getListaAutori() {
    const response = await fetch(
      process.env.REACT_APP_BACKEND + "/listaAutori",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: Cookies.get("utenteToken"),
        },
        body: JSON.stringify({}),
      }
    );
    let res = await response.json();
    console.log(1111, res);
    setListaAutori(res);
  }

  async function getListaGeneri() {
    const response = await fetch(
      process.env.REACT_APP_BACKEND + "/listaGeneri",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: Cookies.get("utenteToken"),
        },
        body: JSON.stringify({}),
      }
    );
    let res = await response.json();
    console.log(1111, res);
    setListaGeneri(res);
  }

  async function query() {
    const response = await fetch(
      process.env.REACT_APP_BACKEND + "/libriLista",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: Cookies.get("utenteToken"),
        },
        body: JSON.stringify({
          cercaTitolo,
          cercaAutore,
          cercaGeneri,
          risPerPagina,
          pagina,
        }),
      }
    );
    let res = await response.json();
    console.log(res);
    const resDisp = controllaDisponibilita(res.libri);
    setLibri(resDisp);
    setTotaleLibri(res.totaleLibri);
  }

  async function elimina(e, id) {
    e.stopPropagation();
    if (!window.confirm("Confermi l'eliminazione?")) {
      return;
    }

    const response = await fetch(
      process.env.REACT_APP_BACKEND + "/libriElimina",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: Cookies.get("utenteToken"),
        },
        body: JSON.stringify({
          id,
        }),
      }
    );
    let res = await response.json();
    query();
  }

  useEffect(() => {
    //console.log(cercaAutore);
    query();
    setPagina(1);
  }, [cercaTitolo, cercaAutore, cercaGeneri]);

  useEffect(() => {
    query();
  }, [pagina]);

  useEffect(() => {
    //mostra libro dopo redirect login
    const idRedirect = new URLSearchParams(location.search).get("id");
    if (idRedirect) {
      setElementoDaAggiornare(idRedirect);
      setApriPrenota(true);
    }
    //
    getListaAutori();
    getListaGeneri();
  }, [location]);

  return (
    <>
      <div className="testataElencoLibri">
        <h1>ELENCO LIBRI</h1>
        <div className="valign wrapBottoneNuovo">
          <span style={{ marginRight: "10px" }}>
            {totaleLibri} Libri totali
          </span>
          {ruoloLogin == "admin" && (
            <>
              <Link to="/libri/nuovo" style={{ textDecoration: "none" }}>
                <img src="/img/aggiungi-nuovo.png"></img>
                {/* <Button variant="contained">Nuovo</Button> */}
              </Link>
            </>
          )}
        </div>
      </div>
      <div className="filtriRicerca">
        <div>
          <TextField
            className="inputInline"
            variant="filled"
            label="Titolo"
            value={cercaTitolo}
            onChange={(e) => setCercaTitolo(e.target.value)}
            sx={{ mr: 2 }}
          />
          {/* <TextField
        label="Autore"
        variant="filled"
        value={cercaAutore}
        onChange={(e) => setCercaAutore(e.target.value)}
      /> */}
          <Autocomplete
            options={listaAutori}
            className="inputInline"
            //value={{autore: cercaAutore}}
            getOptionLabel={(option) => option.autore}
            onInputChange={(event, newValue) => {
              //console.log(newValue);
              setCercaAutore(newValue);
            }}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Autori" variant="filled" />
            )}
          />
          <Autocomplete
            className="inputInline"
            options={listaGeneri}
            variant="filled"
            //value={{genere: cercaGeneri}}
            getOptionLabel={(option) => option.genere}
            onInputChange={(event, newValue) => {
              //console.log(newValue);
              setCercaGeneri(newValue);
            }}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Generi" variant="filled" />
            )}
          />
        </div>
        <Pagination
          count={Math.ceil(totaleLibri / risPerPagina)}
          page={pagina}
          onChange={(e, value) => setPagina(value)}
        />
      </div>
      {/* <TableContainer
      //component={Paper}
      > */}
      {/* <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Id</TableCell>
              <TableCell>Titolo</TableCell>
              <TableCell>Autore</TableCell>
              <TableCell>Casa Editrice</TableCell>
              <TableCell>Fine prestito</TableCell>
              <TableCell>Disponibile</TableCell>
              <TableCell></TableCell>
              {ruoloLogin == "admin" && <TableCell></TableCell>}
              {ruoloLogin == "admin" && <TableCell></TableCell>}
            </TableRow>
          </TableHead> */}
      {/* <TableBody> */}
      <br />
      {libri.map((libro) => {
        let classe = "bloccoLibro";
        if (libro.disponibile == 0) {
          classe += " nonDispFlag";
        }
        return (
          <div className={classe} key={libro.id}>
            <Grid container spacing={2}>
              <Grid item sm={2} xs={4}>
                <img
                  className="immaginelibro pointer"
                  src={
                    libro.immagine
                      ? process.env.REACT_APP_BACKEND +
                        "/media/" +
                        libro.immagine
                      : "/img/segnaposto.jpg"
                  }
                  onClick={() => {
                    setElementoDaAggiornare(libro.id);
                    setApriPrenota(true);
                  }}
                />
              </Grid>

              <Grid item sm={4} xs={8} className="dettagliPrimari">
                <p
                  className="titolo"
                  onClick={() => {
                    setElementoDaAggiornare(libro.id);
                    setApriPrenota(true);
                  }}
                >
                  {libro.titolo}
                </p>
                <p className="autore">{libro.autore}</p>
              </Grid>

              <Grid item sm={3} xs={12}>
                <p>{libro.casaeditrice}</p>
                {libro.dataFine && (
                  <p>Fine prestito: {convertiData(libro.dataFine)}</p>
                )}
                <p>Id: #{libro.idInterno}</p>
                <p>Genere: {libro.genere}</p>
                <p>
                  {libro.disponibilePren ? (
                    <>
                      <span> Disponibile </span>
                      <img src="/img/disponibile.png" />
                    </>
                  ) : (
                    <>
                      <span> Non disponibile</span>{" "}
                      <img src="/img/non-disponibile.png" />
                    </>
                  )}

                  {/* <MenuBookIcon
                    className={
                      libro.disponibilePren ? "libroDisp" : "libroNonDisp"
                    }
                  /> */}
                </p>
                {/* {<Pallino attivo={libro.disponibile} />} */}
              </Grid>
              <Grid item sm={3} xs={12} className="wrapIcone">
                {/* <Button
                  variant="contained"
                  disabled={
                    Cookies.get("conteggioPrenotazioniUtente") > 0
                      ? true
                      : false
                  }
                  onClick={() => {
                    setElementoDaAggiornare(libro.id);
                    setApriPrenota(true);
                  }}
                >
                  Visualizza
                </Button> */}
                {Cookies.get("conteggioPrenotazioniUtente") > 0 ? (
                  //prenotazione non possibile
                  <img src="/img/icon-prenota-grigio_60x60.png" />
                ) : (
                  //prenotazione possibile
                  <img
                    src="/img/icon-prenota-arancio_60x60.png"
                    className="pointer"
                    onClick={() => {
                      setElementoDaAggiornare(libro.id);
                      setApriPrenota(true);
                    }}
                  />
                )}

                {Cookies.get("conteggioPrenotazioniUtente") > 0 ? (
                  <div
                    className="prenAttiva"
                    style={{ width: "100%", textAlign: "right" }}
                  >
                    Hai già una prenotazione attiva
                  </div>
                ) : (
                  ""
                )}
                {ruoloLogin == "admin" && (
                  // <Button
                  //   variant="contained"
                  //   onClick={() => {
                  //     setElementoDaAggiornare(libro.id);
                  //     setApriAggiorna(true);
                  //   }}
                  // >
                  //   Modifica
                  // </Button>
                  <img
                    src="/img/icon-modifica_60x60.png"
                    onClick={() => {
                      setElementoDaAggiornare(libro.id);
                      setApriAggiorna(true);
                    }}
                    className="pointer"
                  />
                )}
                {ruoloLogin == "admin" && (
                  // <div
                  //   className="pointer"
                  //   onClick={(e) => elimina(e, libro.id)}
                  // >
                  // {<DeleteIcon color="error" /> }
                  <img
                    src="/img/icon-elimina_60x60.png"
                    onClick={(e) => elimina(e, libro.id)}
                    className="pointer"
                  />
                  // </div>
                )}
              </Grid>
            </Grid>
          </div>
        );
      })}
      {/* </TableBody> */}
      {/* </Table> */}
      {/* </TableContainer> */}
      <Pagination
        count={Math.ceil(totaleLibri / risPerPagina)}
        page={pagina}
        onChange={(e, value) => setPagina(value)}
      />
      <br />
      <br />
      <LibriAggiorna
        open={apriAggiorna}
        idElem={elementoDaAggiornare}
        fnChiudi={() => {
          setApriAggiorna(false);
          query();
        }}
      />
      <PrenotaLibro
        open={apriPrenota}
        idElem={elementoDaAggiornare}
        fnChiudi={() => {
          setApriPrenota(false);
          query();
        }}
      />
    </>
  );
};

export default Comp;
