import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Cookies from "js-cookie";

export default function Comp(props) {
  const [titolo, setTitolo] = useState("");
  const [autore, setAutore] = useState("");
  const [casaEd, setCasaEd] = useState("");
  const [genere, setGenere] = useState("");
  const [anno, setAnno] = useState("");
  const [descrizione, setDescrizione] = useState("");
  const [lingua, setLingua] = useState("");
  const [pagine, setPagine] = useState("");
  const [ean, setEan] = useState("");
  const [idInterno, setIdInterno] = useState("");

  async function inserisci(e) {
    e.preventDefault();
    const response = await fetch(
      process.env.REACT_APP_BACKEND + "/libriNuovo",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: Cookies.get("utenteToken"),
        },
        body: JSON.stringify({
          titolo,
          autore,
          casaEd,
          genere,
          anno,
          descrizione,
          lingua,
          pagine,
          ean,
          idInterno
        }),
      }
    );
    let res = await response.json();
    console.log(res);
    if(res){
      window.location.href ="/";
    }
  }

  return (
    <>
     <h1>NUOVO LIBRO</h1>
    <form  onSubmit={inserisci}>

      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={6}>
          <TextField
            label="Titolo"
            variant="filled"
            required
            value={titolo}
            fullWidth
            onChange={(e) => setTitolo(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Autore"
            variant="filled"
            required
            value={autore}
            fullWidth
            onChange={(e) => setAutore(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Casa Editrice"
            variant="filled"
            
            value={casaEd}
            fullWidth
            onChange={(e) => setCasaEd(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Genere"
            required
            variant="filled"
            value={genere}
            fullWidth
            onChange={(e) => setGenere(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Anno"
            type="number"
            variant="filled"
            value={anno}
            fullWidth
            onChange={(e) => setAnno(e.target.value)}
          />
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <TextField
            label="Lingua"
            variant="filled"
            value={lingua}
            fullWidth
            onChange={(e) => setLingua(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Pagine"
            type="number"
            variant="filled"
            value={pagine}
            fullWidth
            onChange={(e) => setPagine(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="EAN"
            variant="filled"
            value={ean}
            fullWidth
            onChange={(e) => setEan(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Descrizione"
            multiline
            minRows="5"
            variant="filled"
            value={descrizione}
            fullWidth
            onChange={(e) => setDescrizione(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="ID interno"
            minRows="5"
            variant="filled"
            value={idInterno}
            fullWidth
            onChange={(e) => setIdInterno(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button 
          type="submit"
          variant="contained">
            Salva
          </Button>
        </Grid>
        
      </Grid>
      </form>
    </>
  );
}
