import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LibriAggiorna from "./LibriAggiorna";
import Button from "@mui/material/Button";
import Cookies from "js-cookie";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Pallino, convertiData, controllaLogin } from "../inc/inc";

const Comp = () => {
  const ruoloLogin = controllaLogin();
  const [libri, setLibri] = useState([]);
  const [elementoDaAggiornare, setElementoDaAggiornare] = useState(null);
  const [apriAggiorna, setApriAggiorna] = useState(false);

  const [cercaTitolo, setCercaTitolo] = useState("");
  const [cercaAutore, setCercaAutore] = useState("");
  const [cercaUtente, setCercaUtente] = useState("");

  const [mostraEliminate, setMostraEliminate] = useState(false);

  //const [pagina, setPagina] = useState(1);
  const [risPerPagina, setRisPerPagina] = useState(999999);

  async function elimina(e, id) {
    e.stopPropagation();
    if (!window.confirm("Confermi l'eliminazione?")) {
      return;
    }

    const response = await fetch(
      process.env.REACT_APP_BACKEND + "/prenotazioniElimina",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: Cookies.get("utenteToken"),
        },
        body: JSON.stringify({
          id,
        }),
      }
    );
    let res = await response.json();
    query();
  }

  async function query() {
    const response = await fetch(
      process.env.REACT_APP_BACKEND + "/prenotazioniLista",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: Cookies.get("utenteToken"),
        },
        body: JSON.stringify({
          cercaTitolo,
          cercaAutore,
          cercaUtente,
          risPerPagina,
          mostraEliminate,
        }),
      }
    );
    let res = await response.json();
    console.log(res);
    setLibri(res);
  }

  useEffect(() => {
    query();
  }, [cercaTitolo, cercaAutore, cercaUtente, mostraEliminate]);

  return (
    <>
      <h1>
        {ruoloLogin == "admin" ? "ELENCO PRENOTAZIONI" : "LE MIE PRENOTAZIONI"}
      </h1>
      <TextField
        label="Titolo"
        variant="filled"
        value={cercaTitolo}
        onChange={(e) => setCercaTitolo(e.target.value)}
        sx={{ mr: 2, mb: 2 }}
      />
      {ruoloLogin == "admin" && (
        <TextField
          label="Utente"
          variant="filled"
          value={cercaUtente}
          onChange={(e) => setCercaUtente(e.target.value)}
          sx={{ mr: 2, mb: 2 }}
        />
      )}
      <FormControlLabel
        control={
          <Checkbox onChange={() => setMostraEliminate(!mostraEliminate)} />
        }
        label="Mostra tutte"
      />
      {/* <TextField
        label="Autore"
        variant="filled"
        value={cercaAutore}
        onChange={(e) => setCercaAutore(e.target.value)}
      /> */}
      <br /> <br />
      <TableContainer
      //component={Paper}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Titolo</TableCell>
              <TableCell>Autore</TableCell>
              <TableCell>Utente</TableCell>
              <TableCell>Inizio prestito</TableCell>
              <TableCell>Fine prestito</TableCell>
              {ruoloLogin == "admin" && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {libri.map((libro) => {
              let eliminato,
                dataPrec = "";

              if (libro.eliminato === 1) {
                eliminato = " eliminato";
              }

              if (new Date(libro.dataFine) <= new Date()) {
                dataPrec = " dataPrec";
              }

              return (
                <TableRow
                  className={eliminato + dataPrec}
                  key={libro.id}
                  // onClick={() => {
                  //   setElementoDaAggiornare(libro.id);
                  //   setApriAggiorna(true);
                  // }}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <span className="mobile">Id</span>
                    {libro.id}
                  </TableCell>
                  <TableCell>
                    <span className="mobile">Titolo</span>
                    {libro.titolo}
                  </TableCell>
                  <TableCell>
                    <span className="mobile">Autore</span>
                    {libro.autore}
                  </TableCell>
                  <TableCell>
                    <span className="mobile">Utente</span>
                    {libro.utente}
                  </TableCell>
                  <TableCell>
                    <span className="mobile">Inizio prestito</span>
                    {convertiData(libro.dataInizio)}
                  </TableCell>
                  <TableCell>
                    <span className="mobile">Fine prestito</span>
                    {convertiData(libro.dataFine)}
                  </TableCell>
                  {ruoloLogin == "admin" && (
                    <TableCell>
                      {libro.eliminato !== 1 && (
                        <img
                          className="pointer"
                          onClick={(e) => elimina(e, libro.id)}
                          src="/img/icon-elimina_60x60.png"
                          style={{ height: "44px" }}
                        />
                      )}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <LibriAggiorna
        open={apriAggiorna}
        idElem={elementoDaAggiornare}
        fnChiudi={() => setApriAggiorna(false)}
      />
    </>
  );
};

export default Comp;
