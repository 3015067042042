import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Cookies from "js-cookie";
import Alert from "@mui/material/Alert";
import Input from "@mui/material/Input";
import { flexbox } from "@mui/system";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DragHandleIcon from "@mui/icons-material/DragHandle";

export default function Comp(props) {
  const [email, setEmail] = useState("");
  const [errore, setErrore] = useState(false);
  const [mexErrore, setMexErrore] = useState(null);
  const [mexOk, setMexOk] = useState(null);

  const [segnoOperazione, setSegnoOperazione] = useState(null);
  const [primoValore, setPrimoValore] = useState("");
  const [secondoValore, setSecondoValore] = useState("");
  const [risultatoCaptcha, setRisultatoCaptcha] = useState("");

  async function checkCaptcha(e) {
    e.preventDefault();
    const response = await fetch(process.env.REACT_APP_BACKEND + "/captcha", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        valore1: primoValore,
        valore2: secondoValore,
        operazione: segnoOperazione,
        risultato: risultatoCaptcha,
        email: email,
      }),
    });
    let res = await response.json();
    console.log(res);

    if (res.err) {
      setErrore(true);
      setMexOk(false);
      setMexErrore(res.messaggio);
    } else if (res.ok) {
      setErrore(false);
      setMexOk(res.ok);
    }
    // if (res.admin) {
    //   Cookies.set("utenteAdmin", true, { expires: 30 });
    // }
    // if (res.id) {
    //   Cookies.set("utenteId", res.id, { expires: 30 });
    //   Cookies.set("utenteToken", res.token, { expires: 30 });

    //   //controlla se c'è il cookie per il redirect
    //   if (Cookies.get("redirectLogin")) {
    //     window.location.href = "/?id=" + Cookies.get("redirectLogin");
    //     Cookies.remove("redirectLogin");
    //   } else {
    //     window.location.href = "/";
    //   }
    // } else {
    //   setErrore(true);
    // }
  }

  function captcha_init() {
    var a = Math.floor(Math.random() * 10) + 11;
    var b = Math.floor(Math.random() * 10) + 1;
    var c = Math.floor(Math.random() * 2);

    setPrimoValore(a);
    setSecondoValore(b);

    if (c == 0) {
      setSegnoOperazione(0);
    } else {
      setSegnoOperazione(1);
    }
  }

  useEffect(() => {
    captcha_init();
  }, []);

  return (
    <div style={{ minHeight: "100vh", display: "flex", alignItems: "center" }}>
      <div className="boxlogin">
        <a href="/login">
          <img src="/img/logo_login.png" />
        </a>
        <h2>
          LA NOSTRA <span className="coloreTesto">BIBLIOTECA</span>
        </h2>
        <form onSubmit={(e) => checkCaptcha(e)}>
          <Grid container spacing={2}>
            {!mexOk && (
              <Grid item xs={12}>
                <h2 style={{ marginTop: 0 }}>Recupera Password</h2>
                <div className="inputImmagine">
                  <img src="/img/username.png"></img>
                  <Input
                    className="email"
                    placeholder="Indirizzo Email"
                    variant="filled"
                    value={email}
                    required
                    fullWidth
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </Grid>
            )}
            {!mexOk && (
              <Grid item xs={12}>
                <div className="messages"></div>

                <div className="form-inline">
                  <span>Dimostra di non essere una macchina!</span>
                  <br />
                  <Input
                    name="frm-check-a"
                    value={primoValore}
                    variant="filled"
                    id="frm-check-a"
                    readOnly={true}
                    style={{ width: "50px" }}
                  />
                  <span id="frm-check-c-span">
                    {segnoOperazione == 0 ? (
                      <RemoveIcon style={{ verticalAlign: "middle" }} />
                    ) : (
                      <AddIcon style={{ verticalAlign: "middle" }} />
                    )}
                  </span>
                  <Input
                    name="frm-check-b"
                    value={secondoValore}
                    variant="filled"
                    id="frm-check-b"
                    readOnly={true}
                    style={{ width: "50px" }}
                  />
                  <span>
                    <DragHandleIcon style={{ verticalAlign: "middle" }} />
                  </span>
                  <Input
                    name="frm-check-res"
                    value={risultatoCaptcha}
                    variant="filled"
                    onChange={(e) => setRisultatoCaptcha(e.target.value)}
                    id="frm-check-res"
                    style={{ width: "50px" }}
                    required={true}
                    data-msg-required="Campo di controllo obbligatorio"
                  />
                  <label
                    id="frm-check-res-error"
                    htmlFor="frm-check-res"
                    className="error invalid-feedback"
                  ></label>
                </div>
              </Grid>
            )}

            <Grid item xs={12}>
              {errore && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {mexErrore}
                </Alert>
              )}
              {mexOk && (
                <Alert severity="success" sx={{ mb: 2 }}>
                  <span dangerouslySetInnerHTML={{ __html: mexOk }}></span>
                </Alert>
              )}
              {!mexOk && (
                <Button variant="contained" type="submit" fullWidth>
                  Invia Mail di recupero
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}
