import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { useState, useEffect } from "react";
import { Pallino, convertiData } from "../inc/inc";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Cookies from "js-cookie";
import CircularProgress from "@mui/material/CircularProgress";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Comp(props) {
  const [titolo, setTitolo] = useState("");
  const [autore, setAutore] = useState("");
  const [casaEd, setCasaEd] = useState("");
  const [genere, setGenere] = useState("");
  const [anno, setAnno] = useState("");
  const [descrizione, setDescrizione] = useState("");
  const [lingua, setLingua] = useState("");
  const [pagine, setPagine] = useState("");
  const [ean, setEan] = useState("");
  const [prenotazione, setPrenotazione] = useState("");
  const [disponibile, setDisponibile] = useState("");
  const [immagine, setImmagine] = useState(null);

  const [datiCaricati, setDatiCaricati] = useState(false);

  const [prenotato, setPrenotato] = useState(false);

  async function query(id) {
    setDatiCaricati(false);
    setPrenotato(false);

    const response = await fetch(
      process.env.REACT_APP_BACKEND + "/libriSingolo",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: Cookies.get("utenteToken"),
        },
        body: JSON.stringify({ id }),
      }
    );
    let res = await response.json();
    console.log(res);
    if (res.prenotazione && res.prenotazione.dataFine) {
      res.prenotazione.dataFinePiu1 = new Date(
        res.prenotazione.dataFine
      ).setDate(new Date(res.prenotazione.dataFine).getDate() + 1);
    }
    setAutore(res.autore);
    setCasaEd(res.casaeditrice);
    setTitolo(res.titolo);
    setGenere(res.genere);
    setAnno(res.anno);
    setDescrizione(res.descrizione);
    setLingua(res.lingua);
    setPagine(res.pagine);
    setEan(res.ean);
    setDisponibile(res.disponibile);
    setPrenotazione(res.prenotazione);
    setImmagine(res.immagine);

    if (res.prenotazione && res.prenotazione.dataFine) {
      setPrenotato(true);
    }
    setDatiCaricati(true);
  }

  useEffect(() => {
    console.log(3333, prenotato);
    if (props.idElem) {
      query(props.idElem);
    }
  }, [props.idElem, props.open]);

  const prenotaLibro = async (id, dataInizio) => {
    console.log(22222, Cookies.get("utenteToken"));
    alert("Prenotazione effettuta");
    const response = await fetch(
      process.env.REACT_APP_BACKEND + "/prenotazioniNuovo",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: Cookies.get("utenteToken"),
        },
        body: JSON.stringify({
          id,
          dataInizio,
        }),
      }
    );
    let res = await response.json();
    console.log(res);
    window.location.href = "/prenotazioni";
  };

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.fnChiudi}
      TransitionComponent={Transition}
      className="librovedi"
    >
      <AppBar sx={{ position: "relative", mb: 2 }} className="barraEdit">
        <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.fnChiudi}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <img
              src="/img/logo_main.png"
              style={{ width: "50px", marginRight: "15px" }}
            />

            {/* <Button
            autoFocus
            color="inherit"
            onClick={() => {
              props.fnChiudi();
              aggiornaElemento(props.idElem);
            }}
          >
            save
          </Button> */}
          </Toolbar>
        </Container>
      </AppBar>
      <Container>
        {datiCaricati ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="schedaTop">
                <h1>SCHEDA LIBRO</h1>
                <div className="disponibilita">
                  {prenotato ? (
                    <>
                      <img src="/img/non-disponibile_60x60.png" />
                      <p>Non Disponibile</p>
                    </>
                  ) : (
                    <>
                      <img src="/img/disponibile_60x60.png" />
                      <p>Disponibile</p>
                    </>
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <img
                className="immagineGrandePrenota"
                src={
                  immagine
                    ? process.env.REACT_APP_BACKEND + "/media/" + immagine
                    : "/img/segnaposto.jpg"
                }
              />
            </Grid>
            <Grid item xs={12} sm={8} style={{ display: "flex" }}>
              <div className="riquadroPrenota">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <h2
                      style={{
                        marginTop: 0,
                        marginBottom: "10px",
                        textTransform: "uppercase",
                      }}
                    >
                      {titolo}
                    </h2>
                    <h2
                      style={{
                        marginTop: 0,
                        fontWeight: 300,
                        textTransform: "uppercase",
                      }}
                    >
                      {autore}
                    </h2>
                  </Grid>
                  <Grid item xs={12}>
                    <p>
                      Casa editrice:
                      <b>{casaEd}</b>
                    </p>
                    <p>
                      Genere:
                      <b>{genere}</b>
                    </p>
                    <p>
                      Anno:
                      <b> {anno}</b>
                    </p>
                    <p>
                      Lingua:
                      <b>{lingua}</b>
                    </p>
                    <p>
                      Pagine:
                      <b> {pagine}</b>
                    </p>
                    <p>
                      EAN:
                      <b>{ean}</b>
                    </p>
                  </Grid>

                  {/* bottoni prenota */}
                  <Grid item xs={12} className="bottonePrenota">
                    <p>
                      Stato: {prenotato ? "Non disponibile" : "Disponibile"}
                    </p>
                    {!Cookies.get("utenteToken") ? (
                      <>
                        {prenotato ? (
                          <>
                            Fine prestito: {convertiData(prenotazione.dataFine)}
                            <br />
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                        <Button
                          //sx={{ mt: 1 }}
                          variant="contained"
                          onClick={() => {
                            Cookies.set("redirectLogin", props.idElem);
                            window.location.href = "/login";
                          }}
                        >
                          Login
                        </Button>
                      </>
                    ) : (
                      <>
                        {prenotato ? (
                          //prenotato
                          <>
                            Fine prestito: {convertiData(prenotazione.dataFine)}
                            <br />
                            <p>
                              Prenota a partire da:{" "}
                              {convertiData(prenotazione.dataFinePiu1)}
                            </p>
                            {Cookies.get("conteggioPrenotazioniUtente") > 0 ? (
                              //hai gia 1 prenotazione
                              <img src="/img/icon-prenota-grigio_100x100.png" />
                            ) : (
                              //no prenotazioni
                              <img
                                className="pointer"
                                src="/img/icon-prenota-arancio_100x100.png"
                                onClick={() => {
                                  prenotaLibro(
                                    props.idElem,
                                    new Date(prenotazione.dataFinePiu1)
                                  );
                                  props.fnChiudi();
                                }}
                              />
                            )}
                            {/* </Button> */}
                          </>
                        ) : (
                          //non prenotato
                          <>
                            {Cookies.get("conteggioPrenotazioniUtente") > 0 ? (
                              //hai gia 1 prenotazione
                              <img src="/img/icon-prenota-grigio_100x100.png" />
                            ) : (
                              <img
                                className="pointer"
                                src="/img/icon-prenota-arancio_100x100.png"
                                onClick={() => {
                                  prenotaLibro(props.idElem, new Date());
                                  props.fnChiudi();
                                }}
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                  {/* -bottoni prenota */}
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="boxDescrizione">
                <h2 style={{ fontWeight: 300 }}>DESCRIZIONE</h2>
                {descrizione}
              </div>
            </Grid>
          </Grid>
        ) : (
          <CircularProgress />
        )}
      </Container>
      <br />
      <br />
      <br />
    </Dialog>
  );
}
