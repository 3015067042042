import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LibriAggiorna from "../LibriAggiorna";
import Button from "@mui/material/Button";
import Cookies from "js-cookie";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Pallino, convertiData, controllaLogin } from "../../inc/inc";

const Comp = () => {
  const ruoloLogin = controllaLogin();
  const [libri, setLibri] = useState([]);

  async function query() {
    const response = await fetch(
      process.env.REACT_APP_BACKEND + "/libriPrenotazioni",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: Cookies.get("utenteToken"),
        },
      }
    );
    let res = await response.json();
    console.log(res);
    setLibri(res);
  }

  useEffect(() => {
    query();
  }, []);

  return (
    <>
      <h1>PRENOTAZIONI LIBRI</h1>

      <TableContainer
      //component={Paper}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
            <TableCell>Titolo</TableCell>
              <TableCell>EAN</TableCell>
              <TableCell>Genere</TableCell>
              <TableCell>Numero Prenotazioni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {libri.map((libro) => {
              return (
                <TableRow key={libro.idlibro}>
                  <TableCell>{libro.titolo}</TableCell>
                  <TableCell>{libro.ean}</TableCell>
                  <TableCell>{libro.genere}</TableCell>
                  <TableCell>{libro.Numero_Prenotazioni}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Comp;
