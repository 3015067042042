import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Cookies from "js-cookie";
import Alert from "@mui/material/Alert";
import Input from "@mui/material/Input";
import { flexbox } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";

import CodeIcon from "@mui/icons-material/Code";
import AccountCircle from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import HttpsIcon from "@mui/icons-material/Https";

export default function Comp(props) {
  const [email, setEmail] = useState("");
  const [confermaEmail, setConfermaEmail] = useState("");
  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [cf, setCf] = useState("");
  const [password, setPassword] = useState("");
  const [confermaPassword, setConfermaPassword] = useState("");
  const [privacy, setPrivacy] = useState(false);
  const [condizioni, setCondizioni] = useState(false);

  const [errore, setErrore] = useState(false);
  const [mexErrore, setMexErrore] = useState(null);
  const [mexOk, setMexOk] = useState(null);

  async function registrati(e) {
    e.preventDefault();
    const response = await fetch(
      process.env.REACT_APP_BACKEND + "/registrazione",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          confermaEmail,
          nome,
          cognome,
          cf,
          password,
          confermaPassword,
          privacy,
          condizioni,
        }),
      }
    );
    let res = await response.json();
    console.log(res);

    if (res.err) {
      setErrore(true);
      setMexOk(false);
      setMexErrore(res.messaggio);
    } else if (res.ok) {
      setErrore(false);
      setMexOk(res.ok);
    }
    // if (res.admin) {
    //   Cookies.set("utenteAdmin", true, { expires: 30 });
    // }
    // if (res.id) {
    //   Cookies.set("utenteId", res.id, { expires: 30 });
    //   Cookies.set("utenteToken", res.token, { expires: 30 });

    //   //controlla se c'è il cookie per il redirect
    //   if (Cookies.get("redirectLogin")) {
    //     window.location.href = "/?id=" + Cookies.get("redirectLogin");
    //     Cookies.remove("redirectLogin");
    //   } else {
    //     window.location.href = "/";
    //   }
    // } else {
    //   setErrore(true);
    // }
  }

  useEffect(() => {}, []);

  return (
    <div
    // style={{ minHeight: "100vh", display: "flex", alignItems: "center" }}
    >
      <div className="boxlogin" style={{ marginTop: "50px" }}>
        <a href="/login">
          <img src="/img/logo_login.png" />
        </a>
        <h2>
          LA NOSTRA <span className="coloreTesto">BIBLIOTECA</span>
        </h2>
        <form onSubmit={(e) => registrati(e)} style={{ marginBottom: "80px" }}>
          <Grid container spacing={2}>
            {!mexOk && (
              <Grid item xs={12}>
                <h2 style={{ marginTop: 0 }}>Registrati</h2>
                <div className="inputImmagine">
                  <EmailIcon sx={{ ml: 2 }} />
                  <Input
                    type="email"
                    className="email"
                    placeholder="Indirizzo Email"
                    variant="filled"
                    value={email}
                    required
                    fullWidth
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="inputImmagine">
                  <MarkEmailReadIcon sx={{ ml: 2 }} />
                  <Input
                    type="email"
                    className="email"
                    placeholder="Conferma Indirizzo Email"
                    variant="filled"
                    value={confermaEmail}
                    required
                    fullWidth
                    onChange={(e) => setConfermaEmail(e.target.value)}
                  />
                </div>
                <div className="inputImmagine">
                  <HttpsIcon sx={{ ml: 2 }} />
                  <Input
                    type="password"
                    placeholder="Password"
                    variant="filled"
                    value={password}
                    required
                    fullWidth
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="inputImmagine">
                  <HttpsIcon sx={{ ml: 2 }} />
                  <Input
                    type="password"
                    placeholder="Conferma Password"
                    variant="filled"
                    value={confermaPassword}
                    required
                    fullWidth
                    onChange={(e) => setConfermaPassword(e.target.value)}
                  />
                </div>
                <div className="inputImmagine">
                  <AccountCircle sx={{ ml: 2 }} />
                  <Input
                    placeholder="Nome"
                    variant="filled"
                    value={nome}
                    required
                    fullWidth
                    onChange={(e) => setNome(e.target.value)}
                  />
                </div>
                <div className="inputImmagine">
                  <AccountCircle sx={{ ml: 2 }} />
                  <Input
                    placeholder="Cognome"
                    variant="filled"
                    value={cognome}
                    required
                    fullWidth
                    onChange={(e) => setCognome(e.target.value)}
                  />
                </div>
                <div className="inputImmagine">
                  <CodeIcon sx={{ ml: 2 }} />
                  <Input
                    placeholder="Codice Fiscale"
                    variant="filled"
                    value={cf}
                    required
                    fullWidth
                    onChange={(e) => setCf(e.target.value)}
                  />
                </div>
                <Checkbox
                  checked={privacy}
                  onChange={(e) => setPrivacy(!privacy)}
                  required
                />
                Presa visione dell’<a href="/privacy-policy" target="_blank"><b>informativa privacy</b></a> acconsento al trattamento dei miei dati personali”.
                <br />
                {/* <Checkbox
                  checked={condizioni}
                  onChange={(e) => setCondizioni(!condizioni)}
                  required
                />
                Check Condizioni */}
              </Grid>
            )}

            <Grid item xs={12}>
              {errore && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  <span dangerouslySetInnerHTML={{ __html: mexErrore }}></span>
                </Alert>
              )}
              {mexOk && (
                <Alert severity="success" sx={{ mb: 2 }}>
                  <span dangerouslySetInnerHTML={{ __html: mexOk }}></span>
                </Alert>
              )}
              {!mexOk && (
                <Button variant="contained" type="submit" fullWidth>
                  Registrati
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}
