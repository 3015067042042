import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Cookies from "js-cookie";
import Alert from "@mui/material/Alert";
import Input from "@mui/material/Input";
import { flexbox } from "@mui/system";
import { useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DragHandleIcon from "@mui/icons-material/DragHandle";

export default function Comp(props) {

  let location = useLocation();

  const [errore, setErrore] = useState(false);
  const [mexErrore, setMexErrore] = useState(null);
  const [mexOk, setMexOk] = useState(null);

  async function concludiRegistrazione(token) {
    console.log(token);
    const response = await fetch(process.env.REACT_APP_BACKEND + "/concludi-registrazione", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token
      }),
    });
    let res = await response.json();
    console.log(res);

    if (res.err) {
      setErrore(true);
      setMexOk(false);
      setMexErrore(res.messaggio);
    } else if (res.ok) {
      setErrore(false);
      setMexOk(res.ok);
      setTimeout(()=>{
        window.location.replace("/login")
      }, 8000)
    }
  
  }

  

  useEffect(() => {
    const token = new URLSearchParams(location.search).get("t");
    concludiRegistrazione(token);
  }, [location]);

  return (
    <div style={{ minHeight: "100vh", display: "flex", alignItems: "center" }}>
      <div className="boxlogin">
        <a href="/login">
          <img src="/img/logo_login.png" />
        </a>
        <h2>
          LA NOSTRA <span className="coloreTesto">BIBLIOTECA</span>
        </h2>
          <Grid container spacing={2}>
            {!mexOk && (
              <Grid item xs={12}>
                
              </Grid>
            )}
            
            <Grid item xs={12}>
              {errore && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {mexErrore}
                </Alert>
              )}
              {mexOk && (
                <Alert severity="success" sx={{ mb: 2 }}>
                  <span dangerouslySetInnerHTML={{ __html: mexOk }}></span>
                </Alert>
              )}
            </Grid>
          </Grid>
      </div>
    </div>
  );
}
