import Cookies from "js-cookie";

function Pallino(props) {
  let colore;
  if (props.attivo == 1) {
    colore = "verde";
  } else {
    colore = "rosso";
  }

  return <div className={"pallino " + colore}></div>;
}

function convertiData(input) {
  if (!input) {
    return;
  }
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  var d = new Date(input);
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
}

const controllaLogin = () => {
  //console.log(window.location);

  //se utente non loggato
  //elenco libri visibile anche senza login
  if (
    !Cookies.get("utenteToken") &&
    window.location.pathname != "/login" &&
    window.location.pathname != "/" &&
    window.location.pathname != "/recupera-password" &&
    window.location.pathname != "/registrati" &&
    window.location.pathname != "/concludi-registrazione" &&
    window.location.pathname != "/reimposta-password" &&
    window.location.pathname != "/privacy-policy"
  ) {
    window.location.href = "/login";
    return false;
  }
  //se admin
  if (Cookies.get("utenteAdmin")) {
    return "admin";
  }
  //se non admin
  if (Cookies.get("utenteToken")) {
    return 1;
  }
};

export { Pallino, convertiData, controllaLogin };
