import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Cookies from "js-cookie";
import Alert from "@mui/material/Alert";
import Input from "@mui/material/Input";
import { flexbox } from "@mui/system";
import { useLocation } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DragHandleIcon from "@mui/icons-material/DragHandle";

import HttpsIcon from "@mui/icons-material/Https";

export default function Comp(props) {

  let location = useLocation();

  const [password, setPassword] = useState("");
  const [confermaPassword, setConfermaPassword] = useState("");
  const [id, setId] = useState("");
  const [token, setToken] = useState("");
  const [errore, setErrore] = useState(false);
  const [mexErrore, setMexErrore] = useState(null);
  const [mexOk, setMexOk] = useState(null);


  async function reimpostaPassword(e, id) {
    e.preventDefault();
    console.log(id, password)
    const response = await fetch(process.env.REACT_APP_BACKEND + "/reimposta-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password,
        confermaPassword,
        id: id,
        token
      }),
    });
    let res = await response.json();
    console.log(res);

    if (res.err) {
      setErrore(true);
      setMexOk(false);
      setMexErrore(res.messaggio);
    } else if (res.ok) {
      setErrore(false);
      setMexOk(res.ok);
      setTimeout(()=>{
        window.location.replace("/login")
      }, 8000)
    }
  }



  useEffect(() => {
    setToken(new URLSearchParams(location.search).get("t"));
    setId(new URLSearchParams(location.search).get("id"));
  }, [location]);

  return (
    <div style={{ minHeight: "100vh", display: "flex", alignItems: "center" }}>
      <div className="boxlogin">
        <a href="/login">
          <img src="/img/logo_login.png" />
        </a>
        <h2>
          LA NOSTRA <span className="coloreTesto">BIBLIOTECA</span>
        </h2>
        <form onSubmit={(e) => reimpostaPassword(e, id)}>
          <Grid container spacing={2}>
            {!mexOk && (
              <Grid item xs={12}>
                <h2 style={{ marginTop: 0 }}>Reimposta Password</h2>
                <div className="inputImmagine">
                <HttpsIcon sx={{ ml: 2 }} />
                  <Input
                  type="password"
                    className="password"
                    placeholder="Nuova Password"
                    variant="filled"
                    value={password}
                    required
                    fullWidth
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="inputImmagine">
                <HttpsIcon sx={{ ml: 2 }} />
                  <Input
                  type="password"
                    className="password"
                    placeholder="Conferma Password"
                    variant="filled"
                    value={confermaPassword}
                    required
                    fullWidth
                    onChange={(e) => setConfermaPassword(e.target.value)}
                  />
                </div>
              </Grid>
            )}
          

            <Grid item xs={12}>
              {errore && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  <span dangerouslySetInnerHTML={{ __html: mexErrore }}></span>
                </Alert>
              )}
              {mexOk && (
                <Alert severity="success" sx={{ mb: 2 }}>
                  <span dangerouslySetInnerHTML={{ __html: mexOk }}></span>
                </Alert>
              )}
              {!mexOk && (
                <Button variant="contained" type="submit" fullWidth>
                  Reimposta Password
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}
