import * as React from "react";
import { useState, useEffect } from "react";
import { controllaLogin } from "./inc/inc";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route, Link } from "react-router-dom";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import LibriLista from "./comp/LibriLista";
import Login from "./comp/Login";
import RecuperaPassword from "./comp/RecuperaPassword";
import Registrazione from "./comp/Registrazione";
import PrenotazioniLista from "./comp/PrenotazioniLista";
import ConfermaRegistrazione from "./comp/ConfermaRegistrazione";
import ReimpostaPassword from "./comp/ReimpostaPassword";

import LibriNuovo from "./comp/LibriNuovo";
import AppMenu from "./comp/layout/Appbar";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Admin from "./comp/admin/Admin";
import UtentiPrenotazioni from "./comp/admin/UtentiPrenotazioni";
import LibriPrenotazioni from "./comp/admin/LibriPrenotazioni";
import GeneriRichiesti from "./comp/admin/GeneriRichiesti";
import PaginaPrivacy from "./comp/PaginaPrivacy";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#E54707",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />
        {window.location.pathname != "/login" &&
          window.location.pathname != "/recupera-password" &&
          window.location.pathname != "/registrati" &&
          window.location.pathname != "/concludi-registrazione" &&
          window.location.pathname != "/reimposta-password" && (
            <AppMenu controllaLogin={controllaLogin} />
          )}
        <Container>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="recupera-password" element={<RecuperaPassword />} />
            <Route path="registrati" element={<Registrazione />} />
            <Route
              path="concludi-registrazione"
              element={<ConfermaRegistrazione />}
            />
            <Route path="reimposta-password" element={<ReimpostaPassword />} />

            <Route path="/" element={<LibriLista />} exact />
            <Route path="libri/nuovo" element={<LibriNuovo nuovo={true} />} />
            <Route path="prenotazioni" element={<PrenotazioniLista />} />

            <Route path="admin" element={<Admin />}>
              <Route
                path="utentiprenotazioni"
                element={<UtentiPrenotazioni />}
              />
              <Route path="libriprenotazioni" element={<LibriPrenotazioni />} />
              <Route path="generirichiesti" element={<GeneriRichiesti />} />
            </Route>

            <Route path="privacy-policy" element={<PaginaPrivacy />} />

            {/* <Route path="libri" element={<LibriListaUser />} /> */}
          </Routes>
        </Container>
        {window.location.pathname != "/login" &&
          window.location.pathname != "/recupera-password" &&
          window.location.pathname != "/registrati" &&
          window.location.pathname != "/concludi-registrazione" &&
          window.location.pathname != "/reimposta-password" && (
            <>
              <br />
              <br />
              <div className="barraPiccola" style={{ padding: "5px 0" }}>
                <Container style={{ justifyContent: "right" }}>
                  <a
                    href="/privacy-policy"
                    target="_blank"
                    style={{ color: "#fff" }}
                  >
                    Informativa privacy
                  </a>
                </Container>
              </div>
            </>
          )}
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
