import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Cookies from "js-cookie";
import Alert from "@mui/material/Alert";
import Input from "@mui/material/Input";
import { flexbox } from "@mui/system";
import LockResetIcon from "@mui/icons-material/LockReset";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function Comp(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errore, setErrore] = useState(false);

  async function login() {
    const response = await fetch(process.env.REACT_APP_BACKEND + "/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });
    let res = await response.json();
    console.log(res);
    if (res.admin) {
      Cookies.set("utenteAdmin", true, { expires: 30 });
    }
    if (res.id) {
      Cookies.set("utenteId", res.id, { expires: 30 });
      Cookies.set("utenteToken", res.token, { expires: 30 });

      //controlla se c'è il cookie per il redirect
      if (Cookies.get("redirectLogin")) {
        window.location.href = "/?id=" + Cookies.get("redirectLogin");
        Cookies.remove("redirectLogin");
      } else {
        window.location.href = "/";
      }
    } else {
      setErrore(true);
    }
  }

  return (
    <div style={{ minHeight: "100vh", display: "flex", alignItems: "center" }}>
      <div className="boxlogin">
      <a href="/">
        <img src="/img/logo_login.png" />
        </a>
        <h2>
          LA NOSTRA <span className="coloreTesto">BIBLIOTECA</span>
        </h2>
        {errore && (
          <Alert severity="error" sx={{ mb: 2 }}>
            Credenziali errate
          </Alert>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="inputImmagine">
              <img src="/img/username.png"></img>
              <Input
                className="email"
                placeholder="Email"
                variant="filled"
                value={email}
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="inputImmagine">
              <img src="/img/password.png"></img>
              <Input
                className="password"
                placeholder="Password"
                variant="filled"
                type="password"
                value={password}
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <a href="/registrati">
              {" "}
              <LockResetIcon
                style={{ verticalAlign: "middle", marginRight: "5px" }}
              />
              <span style={{ verticalAlign: "middle" }}>Registrati</span>
            </a>
            <a href="/recupera-password">
              <AccountCircleIcon
                style={{ verticalAlign: "middle", marginRight: "5px" }}
              />
              <span style={{ verticalAlign: "middle" }}>
                Password Dimenticata
              </span>
            </a>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" fullWidth onClick={login}>
              Login
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
