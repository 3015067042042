import Button from "@mui/material/Button";
import { Link, Outlet } from "react-router-dom";

const Comp = () => {
  return (
    <>
    <h1>REPORT PRENOTAZIONI</h1>
      <Link to="/admin/libriprenotazioni">
        <Button variant="contained" sx={{ mr: 2 }}>
          PRENOTAZIONI LIBRI
        </Button>
      </Link>
      <Link to="/admin/utentiprenotazioni">
        <Button variant="contained" sx={{ mr: 2 }}>
        PRENOTAZIONI UTENTI
        </Button>
      </Link>
      <Link to="/admin/generirichiesti">
        <Button variant="contained" sx={{ mr: 2 }}>
          GENERI RICHIESTI
        </Button>
      </Link>
      <br/><br/>
      <Outlet />
    </>
  );
};

export default Comp;
